(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('ProductXmlController', ProductXmlController);

    ProductXmlController.$inject = ['$state', '$scope', 'ParseLinks', 'MessageService', 'paginationConstants', '$uibModal', 'pagingParams',
    'Qinius', 'PlatformWechatSetting', 'ProductXmls', 'ProductXmlFileServes', '$localStorage', 'Store','Principal', 'Article'];

    function ProductXmlController($state, $scope, ParseLinks, MessageService, paginationConstants, $uibModal, pagingParams,
        Qinius, PlatformWechatSetting, ProductXmls, ProductXmlFileServes, $localStorage, Store,Principal, Article) {

        var vm = this;
        Principal.hasAuthority('ROLE_STORE_PRODUCT_XML').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问权限！");
                $state.go("home");
            }
        });

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.jump = jump;
        vm.clear = clear;
        vm.search = search;
        $scope.canEdit = false;
        $scope.canApprove = false;
        $scope.canCancel = false;

        $scope.confirmName = function (id) {
             createModal('app/entities/page/pagesmySuffix-save2.html', 'PageMySuffixSave2Controller', id).result.then(function () {
                 loadAll();
             });
        };

        $scope.updateProductXmls = function (xml){
            createModal('app/entities/product-xml/update-product-xml.html','UpdateProductXmlController',xml).result.then(function () {
                loadAll();
            });
        }

        $scope.cancelArticle = function (id) {
             createModal('app/entities/product-xml/product-xml-cancel-article.html','ProductXmlCancelArticleController',id).result.then(function () {
                loadAll();
            });
        };

        var createModal = function (url, controller, id) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    xmlId:id,
                    pageIndex:vm.page,
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('page');
                        return $translate.refresh();
                    }]
                }
            });
        };

        $scope.batchEdit = function (id) {
            if (!$scope.canCancel) {
                return;
            }
            var ids= [];
            for (var i =  $scope.batchArr.length - 1; i >= 0; i--) {
                var xml = $scope.batchArr[i];
                ids.push(xml.id);
            }
            var idstr = ids.join(",");
            createModal2('app/entities/product-xml/product-xml-batch-edit.html', 'ProductXmlBatchEditController', idstr).result.then(function () {
                 loadAll();
             });
        };


        var createModal2 = function (url, controller, idstr) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    entity: [ '$stateParams', function ( $stateParams) {
                        return idstr;
                    }],
                }
            });
        };


        $scope.batchArr = [];
        $scope.chooseAll = function() {
            $scope.selectAll = !$scope.selectAll;
            $scope.batchArr = [];
            angular.forEach(vm.productXmls, function(xml, i) {
                if ($scope.selectAll) {
                    xml.select = true;
                    $scope.batchArr.push(xml);
                } else {
                    $scope.batchArr = [];
                    xml.select = false;
                }
            });
            $scope.updateBatchAction();
        }

        $scope.chooseOne = function (xml) {
            xml.select = !xml.select;
            // 选中
            if(xml.select){
                $scope.batchArr.push(xml);
            }else{
                // 未选中
                var ind = $scope.batchArr.indexOf(xml);
                if (ind >= 0) {
                    $scope.batchArr.splice(ind, 1);
                }
            }

            // 判断全选按钮
            var flag = true;
            angular.forEach(vm.productXmls,function (xml,i) {
                if(!xml.select){
                    $scope.selectAll = false;
                    flag = false;
                }
                // 全部选中
                if(i==vm.productXmls.length-1&&flag){
                    $scope.selectAll = true;
                }
            });
            $scope.updateBatchAction();
        }

        $scope.updateBatchAction = function(){
            if ($scope.batchArr.length == 0) {
                $scope.canApprove = false;
                $scope.canCancel = false;
                $scope.canEdit = false;
                return;
            }
            $scope.canApprove = true;
            $scope.canCancel = true;
            $scope.canEdit = true;
            for (var i = $scope.batchArr.length - 1; i >= 0; i--) {
                var xml = $scope.batchArr[i];
                if (xml.status != "Created") {
                    $scope.canApprove = false;
                    return;
                }
            }
        }

        $scope.batchUpdateCountTip = function(){
            if (!$scope.canEdit) {
                return;
            }
            var ids= [];
            for (var i =  $scope.batchArr.length - 1; i >= 0; i--) {
                var xml = $scope.batchArr[i];
                ids.push(xml.id);
            }
            var idstr = ids.join(",");
            ProductXmlFileServes.batchUpdateCountTip({ids:idstr}, function(data){
                if (data.status ==200) {
                    loadAll();
                    MessageService.success("操作成功");
                }else{
                    MessageService.error("操作失败");
                }
            }, function(){
                MessageService.error("操作失败");
            })
        }

        $scope.batchUpdateSize = function(){
            if (!$scope.canEdit) {
                return;
            }
            var ids= [];
            for (var i =  $scope.batchArr.length - 1; i >= 0; i--) {
                var xml = $scope.batchArr[i];
                ids.push(xml.id);
            }
            var idstr = ids.join(",");
            ProductXmlFileServes.updatePageSize({ids:idstr}, function(data){
                if (data.status ==200) {
                    loadAll();
                    MessageService.success("操作成功");
                }else{
                    MessageService.error("操作失败");
                }
            }, function(){
                MessageService.error("操作失败");
            })
        }

        $scope.batchDelete = function(){
            if (!$scope.canCancel) {
                return;
            }
            var ids= [];
            for (var i =  $scope.batchArr.length - 1; i >= 0; i--) {
                var xml = $scope.batchArr[i];
                ids.push(xml.id);
            }
            var idstr = ids.join(",");
            MessageService.confirm("确认要删除这些模板？", function () {
                ProductXmls.batchDelete({ids:idstr}, function(data){
                    if (data.status ==200) {
                        loadAll();
                        MessageService.success("操作成功");
                    }else{
                        MessageService.error("操作失败");
                    }
                }, function(){
                    MessageService.error("操作失败");
                })
            }, function () {
            });
        }

         $scope.batchApprove = function( status){
            if (!$scope.canCancel) {
                return;
            }
            var ids= [];
            for (var i =  $scope.batchArr.length - 1; i >= 0; i--) {
                var xml = $scope.batchArr[i];
                if (xml.status == "Approved" || xml.status == "Rejected") {
                    continue;
                }
                if (status=='Approved') {
                    if (xml.categoryId == null) {
                       continue;
                    }
                    if (xml.themeClassifies == null || xml.themeClassifies.length == 0) {
                       continue;
                    }
                    if (xml.type == null) {
                       continue;
                    }
                }
                ids.push(xml.id);
            }
            if (ids.length == 0 && status=='Approved') {
                loadAll();
                return;
            }
            var idstr = ids.join(",");
            MessageService.confirm("确认要审核这些模板？", function () {
                ProductXmls.batchApprove({ids:idstr, status:status}, function(data){
                    if (data.status ==200) {
                        loadAll();
                        MessageService.success("操作成功");
                    }else{
                        MessageService.error("操作失败");
                    }
                }, function(){
                    MessageService.error("操作失败");
                })
            }, function () {
            });
        }

        loadAll();
        $scope.delete = function (id) {
            MessageService.confirm("确认要删除该模板文件么？", function () {
                ProductXmls.delete({id: id}, function () {
                    loadAll();
                });
            }, function () {
            });
        };
        Qinius.get(function (msg) {
            vm.token = msg.token;
        });
        $scope.domains = Qinius.getDomain();

        // vm.platformSetting = PlatformWechatSetting.get();
        vm.platformSetting = Store.getCurrent();

        $scope.newEdit = function (xml) {
            var url = vm.platformSetting.productXmlUrl + '/#/page-my-suffix/'+xml.identifier+'/'+vm.platformSetting.productXmlKey+'/'+xml.id+'/'+xml.name;
            window.location.href = url;
        }

        $scope.approve = function(xml, status) {
            if (xml == null || status == null) {
                return;
            }

            if (status=='Approved') {
                if (xml.categoryId == null) {
                    MessageService.error("请先设置分类");
                    return;
                }
                if (xml.themeClassifies == null || xml.themeClassifies.length == 0) {
                    MessageService.error("请先设置分类");
                    return;
                }
                if (xml.type == null) {
                    MessageService.error("请先设置定制类型");
                    return;
                }
            }
            var s = status == 'Approved' ? "审核通过" : "拒绝"
            MessageService.confirm("确认要" + s + "该模板？", function() {
                ProductXmls.approve({
                    id: xml.id,
                    status: status
                }, function(data) {
                    loadAll();
                }, function() {})
            }, function() {})
        }

        $scope.updateCountTip = function (xml) {
            ProductXmlFileServes.updateCountTip(xml, function () {
                loadAll();
            });
        };

        function loadAll() {
            $scope.batchArr =[];
            $scope.updateBatchAction();
            if (pagingParams.search) {
                ProductXmls.byCondition({
                    items: [{key: "name,themeClassify.name,number", op: "like", value: pagingParams.search},{key: "storeId", op: "=", value: $localStorage.user.storeId}],
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                ProductXmls.byCondition({
                    items: [{key: "storeId", op: "=", value: $localStorage.user.storeId}],
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                return ['id,desc'];
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.allPagesCount = Math.ceil(vm.totalItems/20);
                vm.queryCount = vm.totalItems;
                vm.productXmls = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                MessageService.error("数据加载出错");
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        $(".navbar").show();
        $(".wrap").show().css({'margin': 'margin: 48px 0 0 130px;'});

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: 'id,desc',
                search: vm.currentSearch
            });
        }

        function jump() {
            vm.page = vm.jumpText<=0?1:vm.jumpText;
            console.log(vm.page);
            loadPage(vm.page);
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }


        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
            loadAll();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = null;
            vm.transition();
        }
        //升级
        $scope.version = function (id) {
            createModal('app/entities/product-xml/product-xml-version.html', 'ProductXmlVersionController', id).result.then(function (item) {
                if(item){
                    loadAll();
                }
            });
        };
        $scope.themeClassifie = function (xml) {
            var _return = '';
            xml.themeClassifies.forEach(function (item) {
                _return = _return + item.name + '；'
            })
            if(_return){
                _return = _return.substring(0, _return.length - 1);
            }
            return _return;
        }
    }
})();
